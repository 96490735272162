html {
  height: 100%;
  /* fix for IE11, not needed for chrome/ff */
  margin: 0;
  /* CSS-reset for chrome */
}

body {
  height: 100%;
  display: flex;
  margin: 0;
}

#root {
  height: 100%;
  display: flex;
  margin: 0;
  width: 100%;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .signup-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    justify-content: space-between;
  }

  .signup-main-container-col-holder {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .signup-main-container-col-1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .signup-main-container-col-2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    display: none;
  }

  .signup-main-container-col-2-bg-img {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: "flex-end";
    background-image: url("../public/images/common/login_bg_image.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .signup-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    justify-content: space-between;
  }

  .signup-main-container-col-holder {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .signup-main-container-col-1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .signup-main-container-col-2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    display: none;
  }

  .signup-main-container-col-2-bg-img {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: "flex-end";
    background-image: url("../public/images/common/login_bg_image.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .signup-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    justify-content: space-between;
  }

  .signup-main-container-col-holder {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  .signup-main-container-col-1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .signup-main-container-col-2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    display: none;
  }

  .signup-main-container-col-2-bg-img {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: "flex-end";
    background-image: url("../public/images/common/login_bg_image.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .signup-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    justify-content: space-between;
    max-width: 400px;
  }

  .signup-main-container-col-holder {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  .signup-main-container-col-1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .signup-main-container-col-2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    height: 100%;
    display: block;
  }

  .signup-main-container-col-2-bg-img {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: "flex-end";
    background-image: url("../public/images/common/login_bg_image.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .signup-main-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    justify-content: space-between;
    max-width: 480px;
  }

  .signup-main-container-col-holder {
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  .signup-main-container-col-1 {
    width: 50%;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .signup-main-container-col-2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    height: 100%;
    display: block;
  }

  .signup-main-container-col-2-bg-img {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: "flex-end";
    background-image: url("../public/images/common/login_bg_image.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: top;
  }
}

textarea::placeholder {
  color: #6EB2F7;
  opacity: 5;
  font-family: inherit;
  padding-left: 3px;
}

textarea {
  font-family: inherit;
    font-size: inherit;
    padding-left: 10px;
}

textarea:hover{
  border-color: #0D80F2 !important;
}

textarea:focus{
  border: 2px solid #0D80F2 !important;
}

textarea:disabled{
  border: 1px solid #ABB2BA !important;
  background-color: #F1F2F4 !important;
}

textarea:disabled::placeholder{
  color:#ABB2BA !important
}

.base-NumberInput-input {
  border-radius: 8px 0px 0px 8px !important;
}

.base-NumberInput-root:hover{
  border: 1px solid #0D80F2;
}

.base-NumberInput-root:focus-within {
  border: 2px solid #0D80F2 !important;
}

.base-NumberInput-input::placeholder {
  color: #6EB2F7 !important;
  font-family: 'Roboto', sans-serif !important;
}

.base-NumberInput-input:disabled {
  border: 0px !important;
  background-color: #F1F2F4 !important;
}

.phoneNumberDropdown{
  max-height: 350px;  
  margin-top: 6px;
}


    



